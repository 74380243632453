.submission-container {
    padding-left: 30px;
    padding-right: 0;
    position: absolute;
    left: 25%;
    top:0;
    width: 75%;
    max-height:100%;
    overflow-y: scroll;
}

/*.title-label {*/
    /*cursor: pointer;*/
    /*font-size: 85%;*/
    /*font-weight: 600;*/
    /*width: 100%;*/
/*}*/
