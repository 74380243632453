.ladder-problem-list-container {
    border-radius: 8px;
    overflow: hidden;
    padding-right: 0;
    position: absolute;
    left: 330px;
    top:0;
    width: 870px;
    height: 100%;
}

.ladder-problem-list {
    padding-right: 0;
    padding-left: 6px;
    width: 850px;
    max-height:100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background: white;
}

.ladder-list-container {
    border-radius: 8px;
    height: 100%;
    width: 310px;
    overflow: hidden;
    padding-left: 6px;
    background: white;
}

.ladder-list-container .ant-menu-item-only-child {
    height: 40px !important;
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
}

.ladder-list-container .ant-menu-item-only-child:hover {
    color: #5168E7 !important;
}

.ladder-list-container .ant-menu-submenu-title {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #282828;
    letter-spacing: 0;
}

.ladder-list-container .ant-menu-submenu-title:hover {
    color: #5168E7;
}

.ladder-list-container .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #5168E7;
}

.ladder-list-container .ant-menu-item-selected {
    background: #F8FAFD !important;
    border-right: 0 !important;
    border-left: 4px solid #5168E7 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 0 !important;
}
