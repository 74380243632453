.editor-settings-item {
    height: 50px;
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #282828;
    letter-spacing: 0;
}
.editor-settings-item-title {
    width: 20%;
    float: left;
    padding-top: 3px;
}
.editor-settings-item-selector {
    width: 80%;
    float: left;
}

.ide-tool-button {
    width: 40px;
    padding: 4px 10px;
    font: 14px -apple-system;
    color: #607d8b;
}

.ide-submit-button {
    width: 85px;
    float: right;
    margin-right: 10px;
    height: 32px;
    cursor: pointer;
    border: 1px solid rgb(79, 97, 231);
    margin-left: 15px;
    box-shadow: 0 0 0 1px rgb(79, 97, 231) inset;
    background: #5168E7;
    border-radius: 4px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
}

.ide-submit-button:hover {
    -webkit-box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.4);
            box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.4);
}

.ide-run-code-button {
    width: 100px;
    float: right;
    height: 32px;
    cursor: pointer;
    margin-left: 15px;
    background: #FFFFFF;
    border: 1px solid #5168E7;
    border-radius: 4px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #5168E7;
    letter-spacing: 0;
}

.ide-run-code-button:hover {
    -webkit-box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.4);
            box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.4);
}

.ide-run-code-button:disabled {
    background: rgb(236, 239, 241, 0.8);
    opacity: 0.8;
    cursor: not-allowed;
}

.ide-submit-button:disabled {
    background: rgb(79, 97, 231, 0.8);
    opacity: 0.8;
    cursor: not-allowed;
}


button:focus {
    outline: none;
}

.console-button {
    margin-top: 5px;
    margin-left: 5px;
    float: left;
    font: 14px -apple-system;
    cursor: pointer;
    color: #282828;
}
.console-button:hover {
    color: #5168E7;
}
.test-case-input-outer {
    height: 120px;
    margin-top: 5px;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    background-color: #eceff1;
    position: relative;
}

.test-case-input-container {
    height: 100%;
    padding: 0;
}

.test-case-value-outer {
    padding: 5px 8px 5px 8px;
    border-radius: 3px;
    width: 100%;
}

.test-case-value-container {
    white-space: pre-wrap;
    width: 100%;
    overflow-y: hidden;
    cursor: pointer;
    min-height: 21px;
    word-wrap: break-word;
}

.ace_gutter-layer {
    min-width: 50px !important;
    max-width: 50px !important;
}
.ace_gutter {
    min-width: 50px !important;
    max-width: 50px !important;
}
.ace_layer.ace_gutter-layer.ace_folding-enabled {
    min-width: 50px !important;
    max-width: 50px !important;
}
.ace_gutter-cell {
    width: 50px !important;
    padding-left: 8px !important;
    padding-right: 18px !important;
}
.ace_scroller {
    left: 50px !important;
}
.ace_scrollbar.ace_scrollbar-h {
    left: 50px !important;
}

body {
    /*-webkit-touch-callout: none;*/
    /*-webkit-user-select: none;*/
    /*-khtml-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -khtml-user-select: auto;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
.ide-tool-bar .ant-btn {
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #DFE3E8;
    font-family: PingFangSC-Regular;
    color: #282828;
    letter-spacing: 0;
}
.ide-tool-bar .ant-btn:hover, .ant-btn:focus {
    border: 1px solid #5168E7;
    color: #5168E7;
}

.ant-select {
    font-family: PingFangSC-Regular;
    color: #282828;
    letter-spacing: 0;
}

.ant-select-selector {
    border-radius: 4px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: 1px solid #5168E7;
    color: #5168E7;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.6);
            box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.6);
}

.ant-select-item-option-selected {
  background: #D8E8FF !important;
}

.ant-modal-header {
    border-radius: 20px;
}
.ant-modal-content {
    border-radius: 20px;
}
