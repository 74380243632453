.custom-html-style code, .custom-html-style pre {
    white-space: pre-wrap;
}

.custom-html-style pre {
    background: #f7f9fa;
    padding: 10px 15px;
    color: #263238;
    line-height: 1.6;
    font-size: 13px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-image: initial;
    border-image-source: initial;
    border-image-repeat: initial;
}

.custom-html-style code {
    color: #546e7a ;
    background-color: #f7f9fa ;
    padding: 2px 4px  ;
    font-size: 13px ;
    border-radius: 3px ;
    font-family: monospace ;
}

pre {
    -webkit-touch-callout: default;
    -webkit-user-select: text;
    -khtml-user-select: auto;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.self-link {
    background: #EFF3F9;
    border-radius: 2px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #282828;
    letter-spacing: 0;
    position: absolute;
    top: -40px;
    right: 0;
    cursor: pointer;
    font-weight: 500;
    width: 64px;
    padding: 2px 4px;
    text-align: center;
}

.self-link:hover {
    -webkit-box-shadow: 0 0 0 1px rgba(81, 104, 231, 0.2);
            box-shadow: 0 0 0 1px rgba(81, 104, 231, 0.2);
}
