body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    min-width: 1200px;
    background: #eff3f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import '~antd/dist/antd.css';

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  min-width: 1200px;
  background-color: #eff3f9;
}

::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    margin-right: 20px;
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    display: block;
    min-height: 12px;
    min-width: 6px;
    border-radius: 6px;
    background-color: rgb(217, 217, 217);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(159, 159, 159, 0.7);
}

.ant-table-odd-row {
    background: white;
}
.ant-table-even-row {
    background: #F7F8FA;
}
