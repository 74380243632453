.test-case-option {
    height:20px;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 5px;
}
.test-case-option:hover {
    background: rgba(220, 220, 220);
    font-weight: 700;
}

.test-case-option-selected {
    height:20px;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 5px;
    background: rgba(220, 220, 220);
    font-weight: 700;
}