.home-channel-container {
    border-radius: 20px;
    border: 1px solid #8193ed;
    padding: 8%;
    cursor: pointer;
}

.home-channel-container[disabled] {
    cursor: not-allowed;
}

.home-channel-item {
    position: relative;
    width: 100%;
    height: 100%;
}

.home-channel-container:hover {
    -webkit-box-shadow: 0 0 0 2px rgba(129, 147, 237, 0.6);
            box-shadow: 0 0 0 2px rgba(129, 147, 237, 0.6);
}

.home-channel-figure {
    height: 70%;
    margin-top: -5%;
}
.home-channel-icon {
    position: absolute;
    top: 10%;
    right: 5%;
    width: 10%;
}
.home-channel-title {
    font-family: PingFangSC-Semibold;
    height: 20%;
    font-size: 1.5vw;
    color: #FFFFFF;
    letter-spacing: 0;
    margin: 0;
    position: absolute;
    top: 65%;
}

.home-user-button {
    cursor: pointer;
    opacity: 0.8;
}

.home-user-button:hover {
    opacity: 1;
}

.home-modal-title {
    font-family: Arial-Black;
    font-size: 36px;
    color: #282828;
    letter-spacing: 0;
    margin: 0;
    height: 51px;
    text-align: left;
}

.home-modal-slogan {
    font-family: ArialMT;
    font-size: 22px;
    color: #282828;
    letter-spacing: 0;
    margin: 0;
    height: 29px;
    padding-bottom: 4px;
    text-align: left;
}

.home-modal-input-container {
    margin-top: 16px;
    height: 40px;
    text-align: center;
    font-size: 14px;
    opacity: 0.8;
}

.home-modal-input {
    outline-style: none ;
    outline-width: 0;
    text-shadow: none ;
    border: 0;
    -webkit-appearance: none ;
    -webkit-user-select: text ;
    outline-color: black ;
    box-shadow: none;
    padding: 5px 20px;
    height: 100%;
    color: black;
    border-radius: 8px !important;
    background: rgba(239, 243, 249, 0.8) ;
    font-size: 14px;
}

.home-modal-input:hover {
    background: #EFF3F9 ;
  /*-webkit-box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.25);*/
            /*box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.25);*/
}

.home-modal-input:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.25);
            box-shadow: 0 0 0 2px rgba(81, 104, 231, 0.25);
}

.home-modal-button {
    height: 40px;
    width: 110px;
    background-image: linear-gradient(180deg, #FFA570 0%, #FF5881 100%);
    border-radius: 8px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 0;
    cursor: pointer;
}

.home-modal-button:hover {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 88, 129, 0.4);
            box-shadow: 0 0 0 2px rgba(255, 88, 129, 0.4);
}

.home-modal-button-text {
    line-height: 25px;
    margin: 0;
    padding-top: 7px;
}
