.ladder-create {
    padding-left: 30px;
    position: absolute;
    left: 55%;
    top:0;
    width: 45%;
    height:100%;
}
.ladder-create .ant-tabs-content {
    height: 100%;
}
