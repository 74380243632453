.gutter-row {
    /*background: white;*/
    /*padding: 40px 20px 0 20px !important;*/
    /*margin-top: 40px;*/
}

.ladder-item-container {
    border: 2px solid #EFF3F9;
    border-radius: 8px;
    width: 240px;
    height: 120px;
    cursor: pointer;
    padding: 20px;
    display: flex;
}

.ladder-item-container:hover {
    border: 3px solid rgba(81, 104, 231, 0.2);
   /*-webkit-box-shadow: 0 0 0 3px rgba(81, 104, 231, 0.6);*/
            /*box-shadow: 0 0 0 3px rgba(81, 104, 231, 0.6);*/
}
.ladder-item-title {
    height: auto;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #282828;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 16px;
}

.ladder-item-problems {
    height: 14px;
    font-family: PingFangSC-Regular;
    font-size: 10px;
    color: #919191;
    font-weight: 400;
    margin-top: 3px;
    margin-bottom: 3px;
}
