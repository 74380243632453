.ant-table-thead {
    height: 20px;
}

.difficulty-label-square {
    height: 22px;
    border-radius: 11px;
    display: inline;
    margin: 3px;
    color: white;
    padding: .1em 15px .2em 15px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}

.tag-label-square {
    background: #F2F2F4;
    border-radius: 11px;
    height: 22px;
    /*border-radius: 6px;*/
    opacity: 0.6;
    display: inline;
    margin-right: 6px;
    padding: 0 9px;
    /*font: 6px PingFangSC-Regular;*/
    font-size: 6px;
    color: #565656;
    letter-spacing: 0;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    /*background: #EFF3F9;*/
    border: 1px solid #AFAFAF;
}

/*.tag-label-square {*/
    /*height: 22px;*/
    /*border-radius: 11px;*/
    /*display: inline;*/
    /*margin: 3px;*/
    /*padding: .2em 12px .3em 12px;*/
    /*font-family: PingFangSC-Regular;*/
    /*font-size: 13px;*/
    /*color: #464646;*/
    /*letter-spacing: 0;*/
    /*font-weight: 400;*/
    /*line-height: 1;*/
    /*text-align: center;*/
    /*white-space: nowrap;*/
    /*vertical-align: baseline;*/
    /*background: #EFF3F9;*/
/*}*/

.title-label {
    cursor: pointer;
    line-height: 18px;
    font-weight: 600;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0;
    font-family: PingFangSC-Medium;
    font-size: 13px;
    color: #262626;
    letter-spacing: 0;
}

.title-label:hover {
    color: #1890ff;
}

.title-selector {
    /*display: inline;*/
    /*width: 286px;*/
    height: 32px;
    font-size: 16px;
    margin-left: 5px;
    background: #F2F2F4;
    border-radius: 4px;
    border: 1px solid #d9d9d9 !important;
}

.title-selector input {
    background: #F2F2F4;
}

.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 1px #d9d9d9 !important;
    -webkit-box-shadow: 0 0 0 1px #d9d9d9 !important;
}

.title-selector:focus {
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0 0 0 1px #d9d9d9 !important;
    -webkit-box-shadow: 0 0 0 1px #d9d9d9 !important;
}

.ant-table {
    line-height: 1.05;
 }

th.ant-table-cell {
    height: 50px !important;
    background: #F8FAFD !important;
}

td.ant-table-cell {
   padding: 11px 11px !important;
    max-width: 100% !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: #F0F0F0;
}

.ant-table-title {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    line-height: 18px;
    color: #BBBBBE;
    letter-spacing: 0;
    font-weight: 500;
}

.ant-pagination-item {
    margin: 0 2px !important;
}

.ant-pagination-item-active {
    background: #5168E7;
    border-radius: 2px;
    color: white;
    border-color: #5168E7 !important;
}

.ant-pagination-item-link {
    /*transform: scaleX(-1) rotate(-315deg) !important;*/
    -ms-transform: scale(1.3);
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    color: #464646 !important;
    font-weight: 800 !important;
}

.ant-pagination-item-link:hover {
    /*transform: scaleX(-1) rotate(-315deg) !important;*/
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
}

.ant-pagination-item-active a {
    color: white !important;
}

.icon-button {
    background: #EFF3F9;
    border-radius: 2px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #282828;
    letter-spacing: 0;
    float: right;
    cursor: pointer;
    font-weight: 500;
    width: 32px;
    padding: 2px 4px;
    text-align: center;
    margin: 0 10px;
}

.icon-button:hover {
    -webkit-box-shadow: 0 0 0 1px rgba(81, 104, 231, 0.3);
            box-shadow: 0 0 0 1px rgba(81, 104, 231, 0.3);
}

.drop-box-button {
    height: 32px;
    background: #F2F2F4;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #595959;
    letter-spacing: 0;
    font-weight: 500;
    padding: 5px 10px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
}
.drop-box-button:hover {
    background: #e5e6e8;
}

.div-box-shadow {
    box-shadow: 0 0 2px 2px #e5e6e8;
    -webkit-box-shadow: 0 0 2px 2px #e5e6e8;;
}
